<template>
  <div>
    <div class="min-h-58 d-flex w-100 align-items-center justify-content-center" v-if="!hasSnapshot && !loading">
      <div class="text-center">
        <b-icon icon="calculator" class="h1" />
        <p class="my-3">
          Ainda não há informações dos lançamentos financeiros.
        </p>
        <b-button @click="handleSyncronize()" v-if="is_provider && enabled">
          <b-icon icon="arrow-down-up" class="mr-2" />
          <span>Sincronizar agora</span>
        </b-button>
      </div>
    </div>
    <div v-else>
      <skeleton :rows="20" grid="1" gap="10" v-if="!balance_sheet.data" height="30px" />
      <div v-if="balance_sheet.data">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div class="d-flex align-items-center justify-content-cebter">
            <small class="mr-2">
              Ultima atualização {{ balance_sheet.updated_at | dateFull }}
            </small>
            <unicon v-if="is_provider && enabled" name="redo" @click="handleSyncronize()" class="pointer small"
              style="font-size: 12px" height="16" width="16" />
          </div>

          <div class="d-flex align-items-center justify-content-between mb-2">
            <b-button :variant="period === 'quarters' ? 'light' : 'primary'" size="sm" class="mb-0 mr-2"
              @click="handleChangePeriod('years')">
              Consolidados
            </b-button>
            <b-button :variant="period === 'years' ? 'light' : 'primary'" size="sm" class="mb-0"
              @click="handleChangePeriod('quarters')">
              Trimestrais
            </b-button>
          </div>
        </div>
        <small class="d-block mb-4" v-if="!user.is_admin">
          Os dados financeiros estão incorretos? altere os no
          <router-link target="_blank" to="/my-enterprise/financial/income">
            financeiro de sua empresa
          </router-link>
        </small>
        <div class="mb-4">
          <strong>
            Anexos
          </strong>
          <div v-if="period == 'years'">
            <div class="pointer my-1" @click="openFile(0)" v-if="checkFileType(0)">
              <b-icon icon="patch-check" class="mr-2" />
              <span class="text-nowrap text-success">Balanço Patrimonial Anexado</span>
            </div>
            <div v-else>
              <b-icon icon="patch-exclamation" class="mr-2" />
              <span class="text-nowrap text-danger">Balanço Patrimonial NÂO Anexado</span>
            </div>
            <div class="pointer my-1" @click="openFile(1)" v-if="checkFileType(1)">
              <b-icon icon="patch-check" class="mr-2" />
              <span class="text-nowrap text-success">DRE Anexada</span>
            </div>
            <div v-else>
              <b-icon icon="patch-exclamation" class="mr-2" />
              <span class="text-nowrap text-danger">DRE NÃO Anexada</span>
            </div>
          </div>
          <div v-else class="mt-2">
            <div class="pointer my-1" @click="openFile(2)" v-if="checkFileType(2)">
              <b-icon icon="patch-check" class="mr-2" />
              <span class="text-nowrap text-success">Balanço Patrimonial Anexado</span>
            </div>
            <div v-else>
              <b-icon icon="patch-exclamation" class="mr-2" />
              <span class="text-nowrap text-danger">Balanço Patrimonial NÂO Anexado</span>
            </div>
            <div class="pointer my-1" @click="openFile(3)" v-if="checkFileType(3)">
              <b-icon icon="patch-check" class="mr-2" />
              <span class="text-nowrap text-success">DRE Anexada</span>
            </div>
            <div v-else>
              <b-icon icon="patch-exclamation" class="mr-2" />
              <span class="text-nowrap text-danger">DRE NÃO Anexada</span>
            </div>
          </div>
        </div>
        <table class="d-md-table table b-table table-sm table-responsive w-100 p-0">
          <thead>
            <tr>
              <th :class="index === 1 ? 'text-left pl-0' : 'text-right px-3'" v-for="(head, index) in fields"
                :key="index">
                {{ head }}
              </th>
            </tr>
          </thead>
          <tbody v-if="balance_sheet.data">
            <tr v-for="(item, i) in balance_sheet.data.data" :key="i" :class="!item.is_editable && 'disabled'">
              <template v-for="(subItem, idx) in item.entries[period]">
                <td class="align-middle text-right pr-0" :key="idx" v-if="idx === 0">
                  <strong>{{ item.label }} - </strong>
                </td>
                <td class="align-middle text-left" :key="`T-${idx}`" v-if="idx === 0">
                  <strong> {{ item.name }}</strong>
                </td>

                <td class="align-middle text-right" :key="`item-${idx}`">
                  <b-form-group class="p-0 m-0 table">
                    <span type="text" class="
                        input-loading
                        table-field
                        w-100
                        disabled-input
                        d-flex
                        align-items-center
                        justify-content-end
                        text-muted
                      " :class="{
                        //negative: !item.has_positive_value || subItem.value < 0,                        
                        negative: subItem.value < 0,
                        updating:
                          idx === columnIndex && i === rowIndex && loading,
                      }">
                      {{ formatLabel(subItem, item) }}
                      <b-icon icon="calculator" class="ml-2 calc" :id="`calculation-${idx}-${i}`" />
                      <b-popover :target="`calculation-${idx}-${i}`" placement="top" title="Cáculo da célula"
                        triggers="hover">
                        <strong>{{ getCalcCel(item.formula) }}</strong>
                      </b-popover>
                      <b-icon icon="disc" class="disc" v-if="
                        idx === columnIndex &&
                        rowUpdating.dependents.includes(item.label) &&
                        loading
                      " />
                    </span>
                  </b-form-group>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { url } from "@/services/api";

export default {
  data() {
    return {
      service_id: "",
      provider_id: "",
      period: "quarters",
      invertValue: false,
      enabled: false,
      hasSnapshot: false,
      isEmpty: false,
      loading: false,
      columnIndex: null,
      rowIndex: null,
      activity: {},
      fields: [],
      currentActivity: {},
      balance_sheet: {},
    };
  },

  computed: {
    ...mapGetters(["is_preview", "user", "preview_personify", "is_provider"]),
  },
  methods: {
    ...mapActions(["get_snapshot", "set_snapshot", "show_loading"]),
    handleClickChange() {
      this.invertValue = !this.invertValue;
    },

    handleChangePeriod(period) {
      this.period = period;
      this.setFields();
    },

    formatLabel({ value }, { type_view }) {
      if (typeof value !== "number") {
        parseInt(value);
      }
      if (type_view === "money") {
        const formatter = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BLR",
        });
        return "R$ " + formatter.format(value).replace("BLR", "");
      }
      return `${new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value * 100)}%`;
    },

    setFields() {
      if (this.period === "years") {
        this.fields = ["Código", "Descrição", ...this.balance_sheet.data.years];
      } else {
        const quartes = this.balance_sheet.data.quarters || [[3, 2021]];

        if (!quartes) {
          return;
        }

        this.fields = [
          "Código",
          "Descrição",
          ...quartes.map((q) => `${q[0]}T ${q[1]}`),
        ];
      }
    },
    getSnapshot() {
      this.loading = true;
      this.show_loading({
        loading: true,
      });
      this.get_snapshot({
        type: "balance-sheet",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then((response) => {
        this.enabled = response.enabled;
        if (!response?.created_at) {
          this.hasSnapshot = false;
        } else {
          this.balance_sheet = response;
          this.setFields();
          this.hasSnapshot = true;
          this.activity = response;
        }
        this.show_loading({
          loading: false,
        });
        this.loading = false;
      });
    },
    handleSyncronize() {
      this.$bvModal.show("modal-generating-snapshot");
      this.set_snapshot({
        type: "balance-sheet",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then(() => {
        this.$bvModal.hide("modal-generating-snapshot");
        this.hasSnapshot = true;
        this.getSnapshot();
      });
    },
    getCalcCel(form) {
      const removedParentheses = form.replace(/[\\[\]()]/g, "").split(" ");

      const dataAccountingEntry = this.balance_sheet.data;

      const flatList = dataAccountingEntry.data.map(
        ({ name, code, formula }) => ({
          name,
          code,
          formula,
        })
      );

      const arrFormulas = [];

      removedParentheses.forEach((element) => {
        if (["/", "*", "+", "-", "."].includes(element)) {
          arrFormulas.push(element);
        }
        flatList.forEach((data) => {
          if (element == data.code) {
            arrFormulas.push(data.name);
          }
        });
      });

      const stringformula = arrFormulas.join(" ");
      return stringformula;
    },
    checkFileType(type) {
      const financialFileItem = this.balance_sheet.data.files.find(file => file.type === type);
      if (financialFileItem && Object.keys(financialFileItem.file).length > 0) {
        return true
      } else {
        return false
      }
    },
    openFile(type) {
      const financialFileItem = this.balance_sheet.data.files.find(file => file.type === type);

      if (financialFileItem && financialFileItem.file && financialFileItem.file.file) {
        const urlApi = url.replace('api', '');
        const fileUrl = `${urlApi}${financialFileItem.file.file}`;

        window.open(fileUrl, '_blank');
      } else {
        console.error('File not found for the specified type');
      }
    }
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id || this.$route.query.provider_id;
    this.show_loading({
      loading: true,
    });
    this.getSnapshot();
  },
};
</script>

<style lang="scss" scoped>
.input-loading {
  &.table {
    svg {
      right: auto;
      left: 5px;
      top: 4px;
      display: block !important;
    }
  }
}

tr {
  font-size: 13px;

  &.disabled {
    background: #dae4f5;

    td {
      color: #212529;
    }
  }
}

.table-field {
  border-color: transparent;
  height: 30px !important;

  &.disabled-input {
    margin-right: 13px;
    border-color: transparent !important;

    .calc {
      position: relative;
      top: auto;
      right: auto;
      opacity: 0.7;
      display: flex !important;
      animation: none !important;
    }

    .disc {
      display: flex !important;
      right: auto;
      left: 10px;
      top: 10px;
    }
  }

  &.negative {
    color: $danger !important;
  }

  &.updating {
    text-shadow: 0 0 5px rgba(223, 223, 223, 0.5);
  }
}
</style>
